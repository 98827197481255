.root {
	display: flex;
	align-items: center;
	padding: 6px 16px 8px;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: var(--vkws_landing--navbar-menu-item--color-dark);
	cursor: pointer;
	backdrop-filter: blur(18px);
	border-radius: 8px;
	background: var(--vkws_landings--header_button_light--background_color);
	opacity: 0.8;
}

.root.border {
	border: 1px solid var(--vkws_landings--color--white);
}

.root:hover {
	opacity: 1;
	background: var(--vkws_landings--color--raisin-black);
}

.active, .active:hover {
	background: var(--vkws_landings--color--raisin-black);
}

@media screen and (min-width: 1440px) {
	.root {
		border-radius: 12px;
	}
}
